<template>
    <div>
        <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            size="mini"
            :disabled="type === 'detail'"
            label-width="120px"
            @keyup.enter.native="dataFormSubmit()"
        >
            <el-form-item :label="'角色名称'" prop="roleName">
                <el-input v-model="dataForm.roleName"></el-input>
            </el-form-item>
            <el-form-item :label="'角色编码'" prop="roleCode">
                <el-input v-model="dataForm.roleCode"></el-input>
            </el-form-item>
            <el-form-item :label="'备注'" prop="remark">
                <el-input v-model="dataForm.remark"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'add'
        }
    },
    data() {
        return {
            dataForm: {
                id: 0,
                roleId: 0,
                roleName: '',
                roleCode: '',
                remark: ''
            },
            dataRule: {
                roleName: [
                    {required: true, message: '角色名称不能为空', trigger: 'blur'}
                ]
            }
        };
    },
    methods: {
        // 新增 / 修改
        dataFormSubmit(cb) {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    const menuIdList = [];
                    const menuStatusList = [];
                    const submit = this.dataForm.id ? this.$client.updateRole : this.$client.saveRole;
                    submit({
                        roleId: this.dataForm.id || undefined,
                        roleName: this.dataForm.roleName,
                        roleCode: this.dataForm.roleCode,
                        remark: this.dataForm.remark,
                        menuIdList,
                        menuStatusList
                    }).then((data) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                            });
                            cb();
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                }
            });
        },
    },
    created() {
        this.dataForm.id = this.id;
    }
};
</script>
