<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--row ui-height-100">
        <div style="width: 230px; margin: 20px">
            <el-button type="primary" @click="addRole()" style="width: 100%; border-radius: 4px">+新增</el-button>
            <el-table
                :data="roleDataList"
                highlight-current-row
                :show-header="false"
                @row-click="selectRole"
                :border="false"
                style="width: 100%;"
                height="100%"

            >
                <el-table-column prop="roleId" header-align="center" align="center" label="roleId" width="50">
                </el-table-column>
                <el-table-column prop="roleName" header-align="center" align="center" label="roleName">
                </el-table-column>
                <el-table-column  header-align="center" align="center" width="50" label="操作">
                    <template slot-scope="scope">
                        <el-link><i @click="deleteRole(scope.row)" class="el-icon-delete"></i></el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="ui-layout-flex ui-layout-flex--column ui-height-100 my-content" style="margin-right: 20px">
            <el-tabs v-model="activeName"  class="mytab">
                <el-tab-pane label="角色成员" name="0">
                    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
                        <el-form-item>
                            <el-input v-model="dataForm.key" placeholder="请输入成员名字或手机号" clearable></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" round class="ex-button ex-button--submit" @click="getDataList()"
                            >查询</el-button
                            >
                            <el-button type="primary" round class="ex-button ex-button--reset" @click="reset()"
                            >重置</el-button
                            >
                        </el-form-item>
                        <el-button type="primary" @click="addUser()" style="float: right;">添加成员</el-button>
                    </el-form>
                    <el-table
                        height="100%"
                        :data="dataList"
                        :border="false"
                        highlight-current-row
                    >
                        <el-table-column prop="realName" header-align="center" align="center" label="姓名">
                        </el-table-column>
                        <el-table-column prop="mobile" header-align="center" align="center" label="手机号">
                        </el-table-column>
                        <el-table-column prop="email" header-align="center" align="center" label="邮箱">
                        </el-table-column>
                        <el-table-column  header-align="center" align="center" width="150" label="操作">
                            <template slot-scope="scope">
                                <el-link><i @click="deleteHandle(scope.row)" class="el-icon-delete"></i></el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="margin-top: 10px;text-align: right;">
                        <el-pagination
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="pageIndex"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="pageSize"
                            :total="totalPage"
                            layout="total, sizes, prev, pager, next, jumper"
                        >
                        </el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="功能权限" name="1">
                    <div class="ui-layout-flex ui-layout-flex--column ui-height-100">
                        <div style="display: flex;justify-content: space-between;">
                            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                            <el-button type="primary" @click="saveMenu" style="margin-right: 20px;">保存</el-button>
                        </div>
                        <div class="ui-layout-flex">
                            <div class="menu-head">
                                功能菜单
                            </div>
                            <div class="menu-head1">
                                操作权限<span>(可单独授权)</span>
                            </div>
                        </div>
                        <form id="menu_form"  class="ui-border--top ui-border--right ui-border--left menu-content">
                            <el-checkbox-group v-model="checkedMenus" @change="handleCheckedItemChange">
                                <div class="ui-layout-flex" v-for="item in menuList" :key="item.menuId">
                                    <div class="menu-item ui-border--bottom ui-border--right">
                                        <el-checkbox :label="item.menuId" :name="'m_'+item.menuId" :ref="'menu_'+item.menuId"  @change="selectItem(item)">{{item.name}}
                                        </el-checkbox>
                                    </div>
                                    <div class="ui-layout-flex ui-layout-flex--column menu-item2">
                                        <el-checkbox-group v-model="item.childCheckedMenus" @change="handleCheckedItemChange1">
                                            <div v-for="item1 in item.children" :key="item1.menuId">
                                                <div class="ui-layout-flex">
                                                    <div class="menu-item3 ui-border--bottom ui-border--right">
                                                        <el-checkbox :label="item1.menuId" :ref="'menu_'+item1.menuId" :name="'m_'+item1.menuId"  @change="selectItem1(item1,1)">{{item1.name}}
                                                        </el-checkbox>
                                                    </div>
                                                    <div class="menu-item4 ui-border--bottom">
                                                        <el-checkbox-group v-model="item1.childCheckedMenus"  @change="handleCheckedItemChange2">
                                                            <el-checkbox v-for="(item2, index) in item1.children" :name="'m_'+item2.menuId" :label="item2.menuId" :key="index">{{item2.name}}</el-checkbox>
                                                        </el-checkbox-group>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-checkbox-group>
                                    </div>
                                </div>
                            </el-checkbox-group>
                        </form>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <!-- 弹窗, 新增 / 修改 -->
        <add-role-user v-if="addOrUpdateVisible" ref="ref_addRoleUser" @refreshDataList="getDataList"></add-role-user>
        <!--新增 / 修改-->
        <el-dialog
            v-bind="addOrUpdateDialog"
            width="400px"
            :title="addOrUpdateDialog.title"
            v-if="addOrUpdateDialog.visible"
            :visible.sync="addOrUpdateDialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <add-role ref="AddOrUpdate" :id="addOrUpdateDialog.currentId" :type="addOrUpdateDialog.type"></add-role>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addOrUpdateDialog.visible = false" size="mini">取消</el-button>
                <el-button type="primary" @click="saveRole" size="mini">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import AddRoleUser from './addRoleUser';
import AddRole from './role-add-or-update';
import {treeDataTranslate} from '@/utils';
export default {
    data() {
        return {
            activeName: '0',
            addOrUpdateDialog: {
                visible: false,
                title: '',
                currentId: '',
                type: ''
            },
            dataForm: {
                key: '',
                roleId: 0
            },
            roleDataList: [],
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: false,
            checkAll: false,
            isIndeterminate: false,
            menuForm: {},
            checkedMenus: [],
            checkedMenus1: [],
            checkedMenus2: [],
            menus: [],
            menuList: [],
            tempKey: -666666 // 临时key, 用于解决tree半选中状态项不能传给后台接口问题. # 待优化
        };
    },
    components: {
        AddRoleUser,
        AddRole
    },
    created() {
        this.getRoleDataList();
        this.initMenu();
    },
    methods: {
        handleCheckAllChange(val) {
            this.checkedMenus = val ? this.menus : [];
            this.isIndeterminate = false;
            for (let item of this.menuList) {
                this.selectItem(item, val);
            }
        },
        handleCheckedItemChange(value) {
            console.log('触发：0');
            this.checkedMenus = value;
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.menus.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.menus.length;
        },
        handleCheckedItemChange1(value) {
            console.log('触发：1');
            this.checkedMenus1 = value;
        },
        handleCheckedItemChange2(value) {
            console.log('触发：2');
            this.checkedMenus2 = value;
        },
        selectItem(item, isChecked) {
            let me = this;
            if (typeof isChecked == 'undefined') {
                isChecked = !this.$refs['menu_' + item.menuId][0].isChecked;
            }
            if (isChecked) {
                if (item.children) {
                    let childIds = [];
                    for (let child of item.children) {
                        childIds.push(child.menuId);
                        me.selectItem1(child, 0, isChecked);
                    }
                    item.childCheckedMenus = childIds;
                }
            } else {
                item.childCheckedMenus = [];
                if (item.children) {
                    for (let child of item.children) {
                        me.selectItem1(child, 0, isChecked);
                    }
                }
            }
        },
        selectItem1(item, isClick = 0, isChecked) {
            if (typeof isChecked == 'undefined') {
                isChecked = !this.$refs['menu_' + item.menuId][0].isChecked;
            }
            if (isClick) {
                console.log(isChecked);
                if (isChecked) {
                    this.checkedMenus.push(item.parentId);
                } else {
                    this.checkedMenus.splice(this.checkedMenus.indexOf(item.parentId), 1);
                }
                this.handleCheckedItemChange(this.checkedMenus);
            }
            if (isChecked && item.children) {
                let childIds = item.children.map(child => child.menuId);
                item.childCheckedMenus = childIds;
            } else {
                item.childCheckedMenus = [];
            }
        },
        getElements(formId) {
            let form = document.getElementById(formId);
            let elements = [];
            let tagElements = form.getElementsByTagName('input');
            for (let j = 0; j < tagElements.length; j++) {
                elements.push(tagElements[j]);
            }
            return elements;
        },
        serializeForm(formId) {
            let elements = this.getElements(formId);
            let forms = {};
            for (let el of elements) {
                forms[el.name] = el.checked;
            }
            return forms;
        },
        saveMenu() {
            //let checkedMenus = [...this.checkedMenus, ...this.checkedMenus1, ...this.checkedMenus2];
            //console.log(checkedMenus);
            if (!this.dataForm.roleId) {
                this.$message({
                    message: '请选择角色！',
                    type: 'warning',
                    duration: 1500
                });
                return;
            }
            let forms = this.serializeForm('menu_form');
            let menuIdList = [];
            for (let key in forms) {
                if (forms[key]) {
                    menuIdList.push(key.replace('m_', ''));
                }
            }
            this.$confirm('确定保存权限配置？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.saveRoleMenus({roleId: this.dataForm.roleId, menuIdList}).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                        });
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        initMenu() {
            this.$client.getMenuList2().then(({data}) => {
                for (let item of data) {
                    item.isSel = false;
                    item.childCheckedMenus = [];
                    this.menuForm[item.menuId] = false;
                }
                this.$nextTick(() => {
                    this.menuList = treeDataTranslate(data, 'menuId');
                    for (let item of this.menuList) {
                        this.menus.push(item.menuId);
                    }
                });
            });
            let node = this.$refs.test;
        },
        addUser() {
            if (!this.dataForm.roleId) {
                this.$message({
                    message: '请选择角色！',
                    type: 'warning',
                    duration: 1500
                });
                return;
            }
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.ref_addRoleUser.init({roleId: this.dataForm.roleId});
            });
        },
        reset() {
            this.pageIndex = 1;
            this.dataForm.key = '';
            this.getDataList();
        },
        selectRole(row) {
            this.pageIndex = 1;
            this.dataForm.roleId = row.roleId;
            this.handleCheckAllChange(false);
            this.getDataList();
            this.getRoleMenus();
        },
        getRoleMenus() {
            this.$client.getRoleById(this.dataForm.roleId).then((data) => {
                if (data && data.code === 0) {
                    let menuIdList = data.role.menuIdList;
                    const idx = menuIdList.indexOf(this.tempKey);
                    if (idx !== -1) {
                        menuIdList.splice(idx, menuIdList.length - idx);
                    }
                    this.selectRoleMenus(menuIdList);
                }
            });
        },
        selectRoleMenus(menuIdList) {
            //第一层
            for (let item of this.menuList) {
                if (menuIdList.indexOf(item.menuId) > -1) {
                    this.checkedMenus.push(item.menuId);
                }
                this.selectRoleMenus1(item, menuIdList);
            }
        },
        selectRoleMenus1(menu, menuIdList) {
            //第二层及三层
            if (menu.children) {
                for (let item of menu.children) {
                    if (menuIdList.indexOf(item.menuId) > -1) {
                        menu.childCheckedMenus.push(item.menuId);
                    }
                    this.selectRoleMenus1(item, menuIdList);
                }
            }
        },
        saveRole() {
            this.$refs.AddOrUpdate.dataFormSubmit(rest => {
                this.addOrUpdateDialog.visible = false;
                this.getRoleDataList();
            });
        },
        addRole() {
            this.addOrUpdateDialog = {
                title: '新增角色',
                visible: true,
                type: 'add'
            };
        },
        deleteRole(row) {
            this.$confirm(`确定删除【${row.roleName}】角色吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$client.delRole([row.roleId]).then(data => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500
                            });
                            this.getRoleDataList();
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        getRoleDataList() {
            this.$client.getAllRoleList().then(data => {
                if (data && data.code === 0) {
                    this.roleDataList = data.list;
                } else {
                    this.roleDataList = [];
                }
            });
        },
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true;
            this.$client
                .getUserListByRoleId({
                    current: this.pageIndex,
                    size: this.pageSize,
                    roleId: this.dataForm.roleId,
                    key: this.dataForm.key
                })
                .then(data => {
                    if (data && data.code === 0) {
                        this.dataList = data.data.records;
                        this.totalPage = data.data.total;
                    } else {
                        this.dataList = [];
                        this.totalPage = 0;
                    }
                    this.dataListLoading = false;
                });
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getDataList();
        },
        // 删除
        deleteHandle(user) {
            this.$confirm(`确定删除成员【${user.realName}】吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$client.delUserInRole(this.dataForm.roleId, user.userId).then(data => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500
                            });
                            this.getDataList();
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                })
                .catch(() => {});
        }
    }
};
</script>
<style lang="less">
.mytab {
    height: 100%;
    .el-tabs__content {
        height: calc(100% - 50px);
        .el-tab-pane {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    /*.el-tabs__nav-scroll {
        text-align: center;
        .el-tabs__nav{
            display: inline-block;
            float: none;
        }
    }*/
}
.my-content {
    width: calc(100% - 230px);
    padding-left: 10px;
}
.menu-head,.menu-head1{
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}
.menu-head{
    width: 600px;
}
.menu-head1{
    width: 100%;
    span{
        font-size: 14px;
    }
}
.menu-content{
    margin-top: 5px;
    height: 100%;
    position: relative;
    overflow-y: auto;
    .menu-show-item{
        margin: 0 5px;
        .el-checkbox__label{
            padding-left: 2px !important;
        }
    }
    .menu-item{
        display: flex;
        align-items: center;
        width: 160px;
    }
    .menu-item3{
        display: flex;
        align-items: center;
        width: 300px;
    }
    .menu-item2 ,.menu-item4{
        width: 100%;
    }
    .menu-item ,.menu-item3 ,.menu-item4{
        padding: 10px;
    }
}
</style>
