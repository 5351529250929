<template>
    <el-dialog
        title="选择成员"
        width="950px"
        :close-on-click-modal="false"
        :visible.sync="visible">
        <div class="ui-layout-flex">
            <div class="ui-layout-flex ui-layout-flex--column" style="width: 400px;">
                <h3 style="text-align: center;">所有成员</h3>
                <div class="ui-border ui-padding-10">
                <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
                    <el-form-item>
                        <el-input v-model="dataForm.key" placeholder="请输入成员名字或手机号" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" round class="ex-button ex-button--submit" @click="getDataList()">查询</el-button>
                    </el-form-item>
                </el-form>
                    <el-tree
                        :data="dataList"
                        style="height:400px;overflow-y: auto;"
                        default-expand-all
                        highlight-current
                        ref="org_treeTable"
                        node-key="id">
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span :class="data.hasExist?'my-tree-item-has':'my-tree-item'">{{ data.name }}</span>
                            <span :class="data.hasExist?'my-tree-item-has':'my-tree-item'" style="display: flex;">
                                <span>{{ data.mobile }}</span>
                                <span style="margin-left: 10px;" v-if="data.nodeType==9">
                                    <i v-if="data.hasExist" class="el-icon-check" style="color: #396FFF;"></i>
                                    <el-link v-else @click="addUser(data)"><i  class="el-icon-plus my-tree-item"></i></el-link>
                                </span>
                            </span>
                        </span>
                    </el-tree>
                </div>
            </div>
            <div class="ui-layout-flex" style="width: 100px;justify-content: center;align-items: center;">
                <i class="el-icon-arrow-right" style="font-size: 36px;font-weight: bold;color: #A1A1A1;"></i>
            </div>
            <div class="ui-layout-flex ui-layout-flex--column" style="width: 400px;">
                <h3 style="text-align: center;">已选择成员（{{selectDataList.length}}）</h3>
                <div class="ui-border ui-padding-10" style="height: 450px;">
                    <el-table
                        :data="selectDataList"
                        highlight-current-row
                        :show-header="false"
                        ref="org_treeTable"
                        height="450"
                        style="width: 100%;">
                        <el-table-column
                            prop="name"
                            header-align="center"
                            align="left"
                            label="名称">
                        </el-table-column>
                        <el-table-column
                            prop="mobile"
                            header-align="center"
                            align="left"
                            width="100"
                            label="电话">
                        </el-table-column>
                        <el-table-column header-align="center" align="center" width="50" label="操作">
                            <template slot-scope="scope">
                                <el-link @click="delUser(scope)"><i class="el-icon-close"></i></el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="save()">保存</el-button>
    </span>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            roleId: 0,
            visible: false,
            dataForm: {
                key: ''
            },
            dataList: [],
            selectDataList: [],
        };
    },
    methods: {
        delUser(scope) {
            this.selectDataList.splice(scope.$index, 1);
            scope.row.hasExist = false;
        },
        addUser(row) {
            for (let item of this.selectDataList) {
                if (item.userId === row.userId) {
                    return;
                }
            }
            this.selectDataList.push(row);
            row.hasExist = true;
        },
        init(obj) {
            this.roleId = obj.roleId;
            this.selectDataList = [];
            this.dataList = [];
            this.dataForm.key = '';
            this.visible = true;
            this.$nextTick(() => {
                this.getDataList();
            });
        },
        getDataList() {
            this.$client.getOrgUserTree({key: this.dataForm.key, roleId: this.roleId}).then((data) => {
                this.dataList = data.data;
            });
        },
        save() {
            let userIdList = this.selectDataList.map(item => {
                return item.userId;
            });
            this.$client.addRoleUsers({roleId: this.roleId, userIdList}).then(data => {
                if (data && data.code === 0) {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500
                    });
                    this.visible = false;
                    this.$emit('refreshDataList');
                } else {
                    this.$message.error(data.msg);
                }
            });
        }
    }
};
</script>
<style lang="less">
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .my-tree-item{
        color: #d1d1d1;
    }
    .my-tree-item-has{
        color: #606266;
    }
}
</style>
